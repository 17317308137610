import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectListScene, selectSceneSelected } from '../selectors';
import { actions } from '../slice';
import { I_Scene } from '../types';
import { useGlobalState } from '../global/TourStats';

const useGeneralFunction = () => {
  const dispatch = useDispatch();
  const listScene = useSelector(selectListScene);
  const sceneSelected = useSelector(selectSceneSelected);

  const [isOpenDialogGuide, setIsOpenDialogGuide] = useGlobalState(
    'isVisibleDialogGuide',
  );
  const [isOpenDialogNavigate, setIsOpenDialogNavigate] = useGlobalState(
    'isVisibleDialogNavigate',
  );

  const indexSceneSelected = useMemo(
    () => listScene.findIndex(item => sceneSelected?.id === item.id),
    [listScene, sceneSelected],
  );

  const nextScene = useMemo(
    () =>
      listScene.length
        ? listScene[
            indexSceneSelected === listScene.length - 1
              ? 0
              : indexSceneSelected + 1
          ]
        : undefined,
    [indexSceneSelected, listScene],
  );

  const onNextScene = useCallback(
    (_scene?: I_Scene) => {
      if (_scene && JSON.stringify(_scene) !== JSON.stringify(sceneSelected)) {
        dispatch(actions.setSceneSelected(_scene));
        dispatch(
          actions.setprevSceneSelected(
            _scene.id !== sceneSelected.id ? sceneSelected : null,
          ),
        );
      }
    },
    [sceneSelected, dispatch],
  );

  const onOpenDialogGuide = useCallback(
    () => setIsOpenDialogGuide(true),
    [setIsOpenDialogGuide],
  );

  const onOpenDialogNavigate = useCallback(
    () => setIsOpenDialogNavigate(true),
    [setIsOpenDialogNavigate],
  );

  const onCloseDialogGuide = useCallback(
    () => setIsOpenDialogGuide(false),
    [setIsOpenDialogGuide],
  );

  const onCloseDialogNavigate = useCallback(() => {
    setIsOpenDialogNavigate(false);
    if (isOpenDialogGuide === null) onOpenDialogGuide();
  }, [isOpenDialogGuide, onOpenDialogGuide, setIsOpenDialogNavigate]);

  return useMemo(
    () => ({
      nextScene,
      onNextScene,
      isOpenDialogGuide,
      isOpenDialogNavigate,
      onOpenDialogNavigate,
      onCloseDialogGuide,
      onCloseDialogNavigate,
    }),
    [
      nextScene,
      onNextScene,
      isOpenDialogGuide,
      isOpenDialogNavigate,
      onCloseDialogGuide,
      onCloseDialogNavigate,
      onOpenDialogNavigate,
    ],
  );
};

export default useGeneralFunction;
